import styled from "styled-components";


export const ApplyForJobProccessContainer = styled.div`

padding: 1rem;  
width: 40%;
    .proccess_btn_container{
        display: flex;
    justify-content: space-between;
    flex-direction: column;

    }

    .proccess_btn{
        border-top: .3px solid gray;
        border-bottom: .3px solid gray;
        padding: .6rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
   }
`