export const jobMockData = [
  {
    id: 1,
    position: "Software Enginneer",
    title: "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
    description:
      "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
  },
  {
    id: 2,
    position: "Marketer",
    title: "Mens Casual Premium Slim Fit T-Shirts ",
    description:
      "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
  },
  {
    id: 3,
    position: "Banker",
    title: "Mens Cotton Jacket",
    description:
      "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
  },
  {
    id: 4,
    position: "Driver",
    title: "Mens Casual Slim Fit",
    description:
      "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
  },
  {
    id: 5,
    position: "Care Taker",
    title:
      "John Hardy Women's Legends Naga Gold & Silver Dragon Station Chain Bracelet",
    description:
      "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
  },
];



export const medicalsMockData =[
  {
    'id':1,
    'position':'Tomiwa Ayandele',
    'Company':'ABC Company',
    'date_of_upload':'2023-05-23',
    'CandidatesApplied':'400',
    'status':'Applied'
  },
  {
    'id':2,
    'position':'Tomiwa Ayandele',
    'Company':'ABC Company',
    'date_of_upload':'2023-05-23',
    'CandidatesApplied':'400',
    'status':'Applied'
  },
  {
    'id':3,
    'position':'Tomiwa Ayandele',
    'Company':'ABC Company',
    'date_of_upload':'2023-05-23',
    'CandidatesApplied':'400',
    'status':'Applied'
  },
]