import CompanySettings from "../../components/Compant-Settings/CompanySettings";

function CompanySettingsPage() {
  return (
    <>
      <CompanySettings />
    </>
  );
}

export default CompanySettingsPage;
