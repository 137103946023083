import CompanyJobTestManagement from "../../components/Company-Job-Test-Management/CompanyJobTestManagement";

type Props = {};

function CompanyJobTestManagementPage({}: Props) {
  return (
    <>
      <CompanyJobTestManagement />
    </>
  );
}

export default CompanyJobTestManagementPage;
