import MedicalsSubmissionTables from "../components/Tables/MedicalsSubmissionTables/MedicalsSubmissionTables"



export const MedicalsListSubbmission = ()=>{
    return (
        <div>
            <MedicalsSubmissionTables/>
        </div>
    )
}

export default MedicalsListSubbmission