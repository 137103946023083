import React from "react";
import CompanyMedicals from "../../components/Company-Medicals/CompanyMedicals";

type Props = {};

function CompanyMedicalsPage({}: Props) {
  return (
    <>
      <CompanyMedicals />
    </>
  );
}

export default CompanyMedicalsPage;
