export const medicalsMockData = [
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-1",
    job_type: "Business Development",
  },
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-2",
    job_type: "Business Development",
  },
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-3",
    job_type: "Business Development",
  },
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-4",
    job_type: "Business Development",
  },
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-5",
    job_type: "Business Development",
  },
  {
    job_title: "Tomiwa AyanDele",
    location: "2023-10-6",
    job_type: "Business Development",
  },
];
